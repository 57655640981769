import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ImagesService } from '../../../../core/api';
import { GalleriesService } from '../../../../core/api/galleries/galleries.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/store/app-reducer';
import { SetTemplatesLoadingFlagAction } from '../../../../core/store/templates/templates.actions';
import { WidgetTypeFieldConfiguration } from '../../../../core/store/widget-types/widget-types.model';
import { FilesService } from '../../../../core/api/files/files.service';
import { Router } from '@angular/router';
import { ModalsService } from '../../../../shared/modals/modals.service';
import { hasRequiredValidation } from '../has-required-validation/has-required-validation';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { addUILocale } from '../../../i18n-utilities/i18n-utilities';
import { generateHelpTooltip } from '../info-tooltip.text';

enum MediaTypes {
  Images = 'images',
  Files = 'files',
  Galleries = 'galleries',
}
@Component({
  selector: 'gd-media-widget-configuration',
  templateUrl: './media-widget-configuration.component.html',
  styleUrls: ['./media-widget-configuration.component.scss']
})
export class MediaWidgetConfigurationComponent implements OnInit, OnChanges {
  @Input() fieldControl: UntypedFormControl;
  @Input() mediaType: MediaTypes;
  @Input() fieldConfig:  WidgetTypeFieldConfiguration;
  @Input() hasActionPermission = true;
  @Input() contentLocaleId = null;
  @Input() readOnlyMode = false;

  previewImageUrl;
  isIconPreview;
  isCustomSvgIcon = false;
  isFormControlRequired;

  // File path used for navigation on files edit form
  selectedFilePath = null;
  selectedFile = null;
  isReferenceItemDeleted;
  isGalleryPublished = true;
  mediaSourceType;

  get mediaTypes() {
    return MediaTypes;
  }

  filterField: UntypedFormControl = new UntypedFormControl();
  data$: Observable<any>;
  multiSelectFieldChips;

  infoTooltip = '';

  constructor(
    private imagesService: ImagesService,
    private galleriesService: GalleriesService,
    private store: Store<AppState>,
    private modalsService: ModalsService,
    private filesService: FilesService,
    private router: Router
  ) { }

  ngOnInit() {
    this.validateFieldConfig();
    const id = this.fieldControl.value;
    this.isFormControlRequired = hasRequiredValidation(this.fieldConfig);

    if (this.isFormControlRequired) {
      this.fieldControl.setValidators(Validators.required);
    }
    const isMultiSelect = this.fieldConfig.inputType === 'multiple';
    if (isMultiSelect) {
      this.filterField.valueChanges
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((filterField) => {
          this.data$ = this.filesService
            .getFiles({ searchName: filterField })
            .pipe(map((files) => files && files.content?.filter(item => item.type === 'file')));
        });
    }
    if (!id) {
      return;
    }
    this.store.dispatch(new SetTemplatesLoadingFlagAction(true));
    if (isMultiSelect) {
      this.loadSelectedItems(id)
      return;
    }
    this.loadSelectedItem(id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.infoTooltip = generateHelpTooltip(this.fieldConfig.description, this.readOnlyMode);
  }

  loadSelectedItem (id) {
    switch (this.mediaType) {
      case 'images':
        return this.loadImage(id);
      case 'galleries':
        return this.loadGallery(id);
      case 'files':
        return this.loadFile(id);
      default:
        return '';
    }
  }

  loadImage(id) {
    this.imagesService.getImage(id)
      .then(image => {
        this.store.dispatch(new SetTemplatesLoadingFlagAction(false));
        image = image && this.imagesService.processImage(image);
        this.previewImageUrl = image ? image.thumbnail : '';
      }).catch(() => {
        this.store.dispatch(new SetTemplatesLoadingFlagAction(false));
        this.previewImageUrl = '';
        this.isReferenceItemDeleted = true;
      });
  }

  loadGallery(id) {
    this.galleriesService.getGalleryDetails(id, false).toPromise()
      .then(gallery => {
        this.store.dispatch(new SetTemplatesLoadingFlagAction(false));
        this.previewImageUrl = gallery && gallery.promoImage ? gallery.promoImage.thumbnail : '';
        this.isGalleryPublished = gallery.published_revision_id || false;
      }).catch(() => {
        this.store.dispatch(new SetTemplatesLoadingFlagAction(false));
        this.previewImageUrl = '';
        this.isReferenceItemDeleted = true;
        this.isGalleryPublished = false;
      });
  }

  loadFile(id) {
    this.filesService.getFileById(id).then(file => {
      this.store.dispatch(new SetTemplatesLoadingFlagAction(false));
      const type = file.filename.match(/[^.]+$/g)[0];
      this.previewImageUrl = this.filesService.resolveFileThumbnail(type);
      this.isIconPreview = this.previewImageUrl.startsWith('fa-') || this.previewImageUrl.startsWith('glideCustomIcon_') ? true : false;
      this.selectedFile = file;
      this.selectedFilePath = file.path;
      if (this.isIconPreview) {
        this.isCustomSvgIcon = this.previewImageUrl.startsWith('glideCustomIcon_') ? true : false;
      }
    }).catch(() => {
      this.store.dispatch(new SetTemplatesLoadingFlagAction(false));
      this.previewImageUrl = '';
      this.isReferenceItemDeleted = true;
    });
  }

  openImageDialog () {
    let dialogOptions: any = { showSearch: true };
    if (this.mediaType === 'galleries') {
      dialogOptions = {
        dialogContent: 'galleries',
        showTabs: false,
        showSearch: true,
        contentLocaleId: this.contentLocaleId
      };
    }
    const sub = this.modalsService.embedImage({ dialogOptions }).subscribe(event => {
      if (!event || !event['images']) {
        return sub.unsubscribe();
      }
      const imageData = event['images'][0];
      const mediaItem = imageData.image || imageData;
      this.fieldControl.setValue(mediaItem.id);
      this.fieldControl.markAsDirty();
      this.previewImageUrl = mediaItem.thumbnail || '';
      this.isReferenceItemDeleted = false;
      if (this.mediaType === 'galleries') {
        this.isGalleryPublished = imageData.isPublished || false;
      }
      sub.unsubscribe();
    });
  }

  openFileDialog () {
    let data: any = {};
    const isMultipleSelect = this.fieldConfig.inputType && this.fieldConfig.inputType === 'multiple';
    if (isMultipleSelect) {
      data = {
        uploadConfig: {
          multiUpload: true,
          multipleSelect: true,
        },
        usage: 'mediaWidgetConfig',
        selectedFiles: this.multiSelectFieldChips,
      };
    }
    const sub = this.modalsService.embedFile(data).subscribe(event => {
      if (!event || !event['files']) {
        return sub.unsubscribe();
      }
      if (isMultipleSelect) {
        this.multiSelectFieldChips = event.files;
        this.fieldControl.setValue(this.multiSelectFieldChips.map(item => item.id));
      }
      if (!isMultipleSelect) {
        const fileData = event.files;
        this.selectedFilePath = fileData.path;
        this.selectedFile = fileData;
        this.fieldControl.setValue(fileData.id);
        const type = fileData.filename.match(/[^.]+$/g)[0];
        this.previewImageUrl = this.filesService.resolveFileThumbnail(type);
        this.isIconPreview = this.previewImageUrl.startsWith('fa-') || this.previewImageUrl.startsWith('glideCustomIcon_') ? true : false;
        if (this.isIconPreview) {
          this.isCustomSvgIcon = this.previewImageUrl.startsWith('glideCustomIcon_') ? true : false;
        }
      }
      this.isReferenceItemDeleted = false;
      this.fieldControl.markAsDirty();
      sub.unsubscribe();
  });
  }

  selectMediaItem(e) {
    e.preventDefault();
    if (this.mediaType === 'images' || this.mediaType === 'galleries') {
      return this.openImageDialog();
    }
    this.openFileDialog();
  }

  resolveMediaType() {
    switch (this.mediaType) {
      case 'images':
        return 'Image';
      case 'galleries':
        return 'Gallery';
      case 'files':
        return 'File';
      default:
        return '';
    }
  }

  removeItem() {
    this.fieldControl.setValue(null);
    this.fieldControl.markAsDirty();
    this.previewImageUrl = null;
    this.selectedFilePath = null;
    this.selectedFile = null;
    this.isIconPreview = null;
  }

  navigateToItem(itemId) {
    let url;
    switch (this.mediaType) {
      case 'images':
        url = '/media/images/' + itemId;
        break;
      case 'galleries':
        url = '/media/galleries/' + itemId;
        break;
      case 'files':
        url = '/media/files/edit/' + itemId + '?type=file'
        break;
    }
   this.router.navigate([]).then(() => { window.open(addUILocale(url), '_blank'); });
  }

  getMediaReferenceIssueTooltip(mediaType) {
    return mediaType === this.mediaTypes.Files
      ? $localize`File has been deleted!`
      : $localize`Image has been deleted!`;
  }

  getGalleryReferenceIssueTooltip() {
    return this.isReferenceItemDeleted
      ? $localize`Gallery has been deleted!`
      : $localize`Gallery has been unpublished!`;
  }


  updateFilterFieldValidation() {
    if (this.isFormControlRequired) {
      this.filterField.markAsTouched();
      this.filterField.updateValueAndValidity();
      this.fieldControl.markAsTouched();
      this.fieldControl.updateValueAndValidity();
    }
  }

  selectItem(e) {
    this.filterField.setValue('');
    const selectedItem = e.option.value;
    const selectedItems = this.multiSelectFieldChips || [];
    const itemExist = !!selectedItems.find(item => item.id === selectedItem.id);
    if (itemExist) {
      return;
    }
    this.multiSelectFieldChips = [...selectedItems, selectedItem];
    this.fieldControl.setValue(this.multiSelectFieldChips.map(item => item.id));
    this.filterField.markAsTouched();
    this.fieldControl.markAsDirty();
  }

  async loadSelectedItems(ids) {
    let fileList = []
    await Promise.all(ids.map(async(id) => {
      await this.filesService.getFileById(id).then(file => {
        fileList = [...fileList, file];
      }).catch((e) => {
        fileList = [...fileList, { id, deleted: true }];
      });
    }))
    const sortedList = ids.map(id => fileList.find(file=> file.id === id));
    this.multiSelectFieldChips = sortedList;
    this.store.dispatch(new SetTemplatesLoadingFlagAction(false));
  }

  removeMultipleItem(id) {
    this.multiSelectFieldChips = this.multiSelectFieldChips.filter(item => item.id !== id);
    this.fieldControl.setValue(this.multiSelectFieldChips.map(item => item.id));
    this.filterField.markAsTouched();
    this.fieldControl.markAsDirty();
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.multiSelectFieldChips, event.previousIndex, event.currentIndex);
    this.fieldControl.setValue(this.multiSelectFieldChips.map(item => item.id));
    this.fieldControl.markAsDirty();
  }

  // added to handle breaking change caused by adding "mediaSelect" field type
  validateFieldConfig() {
    this.fieldConfig.fieldType = this.fieldConfig.fieldType || 'mediaSelect';
    if (this.fieldConfig.inputType && !['single', 'multiple'].includes(this.fieldConfig.inputType)) {
      this.fieldConfig.inputType = null;
    }
  }
}
